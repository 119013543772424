export default class PanelMapper {
 constructor() {
   this.allPanels = panelData.sort((a, b) => a.title.localeCompare(b.title));
 }


 getEnv() {
   let env = "PROD";
   if (window.location.href.includes("squads")) env = "TESTE";
   if (window.location.href.includes("homolog")) env = "HOMOLOG";
   return env;
 }


 list(query, type) {
   let byType = this.allPanels;
   if (type !== "all") byType = this.allPanels.filter((p) => !!p[type]);


   const byEnv = byType


   return byEnv.filter((p) =>
     this.unnacent(p.title).includes(this.unnacent(query))
   );
 }


 getUrl(panelName, type) {
   const key = this.allPanels.find((p) => p.title === panelName)?.[type];
   if (!key) return "";
   let baseUrl = "";
    if (type === "estatistico")
      baseUrl = "/relatorio-dinamico/relatorio?mobile=true&p=";
    if (type === "geoespacial")
      baseUrl = "/plataforma-geoespacial/#/";


   return `${baseUrl}${key}`;
 }


 unnacent(input) {
   return input
     .normalize("NFD")
     .replace(/[\u0300-\u036f]/g, "")
     .toLowerCase();
 }
}


const panelData = [
 {
   title: "Agricultura Familiar",
   icon: require("../assets/imgs/panels/full_agricultura_familiar.png"),
   geoespacial: "AGRICULTURA_FAMILIAR",
   estatistico: "agricultura-familiar/dap",
 },
 {
   title: "Agropecuária Sustentável e Meio Ambiente",
   icon: require("../assets/imgs/panels/full_agro_sustentavel.png"),
   geoespacial: "AGROPECUARIA_SUSTENTAVEL",
   estatistico: "agropecuaria-sustentavel-meio-ambiente/irrigacao",
 },
 {
   title: "Aquicultura",
   icon: require("../assets/imgs/panels/full_aquicultura.png"),
   geoespacial: "AQUICULTURA_E_PESCA",
   estatistico: "aquicultura/engorda",
 },
 {
   title: "Assistência Técnica",
   icon: require("../assets/imgs/panels/full_assistencia_tecnica.png"),
   geoespacial: "ASSISTENCIA_TECNICA",
   estatistico: "assistencia-tecnica/assistentencia-tecnica-visao-geral",
 },
 {
   title: "Assuntos Fundiários",
   icon: require("../assets/imgs/panels/full_assuntos_fundiarios.png"),
   geoespacial: "ASSUNTOS_FUNDIARIOS",
   estatistico: "assuntos-fundiarios/glebas-publicas-federais",
 },
 {
   title: "Comércio Exterior",
   icon: require("../assets/imgs/panels/full_comex.png"),
   geoespacial: null,
   estatistico: "comercio-exterior/agronegocio",
 },
 {
   title: "Crédito Rural",
   icon: require("../assets/imgs/panels/full_credito_rural.png"),
   geoespacial: "CREDITO_RURAL",
   estatistico: "credito-rural/programas",
 },
 {
   title: "Fertilizantes",
   icon: require("../assets/imgs/panels/full_fertilizantes.png"),
   geoespacial: null,
   estatistico: "fertilizantes/visao-geral",
 },
 {
   title: "Indicações Geográficas",
   icon: require("../assets/imgs/panels/full_indicacoes_geograficas.png"),
   geoespacial: "PAINEL_GERAL",
   estatistico: null,
 },
 {
   title: "Recursos Hídricos e Agricultura",
   icon: require("../assets/imgs/panels/full_hidrico.png"),
   geoespacial: "HIDROGRAFIA",
   estatistico: null,
 },
 {
   title: "Destaques do Censo Agropecuário",
   icon: require("../assets/imgs/panels/full_censo.png"),
   geoespacial: "CENSO_AGROPECUARIO",
   estatistico: null,
 },
 {
   title: "Indicadores",
   icon: require("../assets/imgs/panels/full_indicadores.png"),
   geoespacial: null,
   estatistico: "indicadores/valores-brutos-de-producao",
 },
 {
   title: "Pecuária",
   icon: require("../assets/imgs/panels/full_pecuaria.png"),
   geoespacial: "PECUARIA",
   estatistico: "pecuaria/bovinos",
 },
 {
   title: "Produtos Agrícolas",
   icon: require("../assets/imgs/panels/full_prod_agricola.png"),
   geoespacial: "PRODUTOS_AGRICOLAS",
   estatistico: "producao-agricola/algodao",
 },
 {
   title: "PronaSolos",
   icon: require("../assets/imgs/panels/full_pronasolos.png"),
   geoespacial: null,
   estatistico: "pronasolos/visao-geral",
 },
 {
   title: "ZARC",
   icon: require("../assets/imgs/panels/full_zarc.png"),
   geoespacial: "ZARC",
   estatistico: "zarc/visao-geral",
 },
];