import PanelListing from "../../components/panelListing";
import PanelNav from "../../components/panelNav";
import "./index.css";

function PanelDisplay({ type }) {
  return (
    <section>
      <PanelListing type={type} />
      <PanelNav type={type} />
    </section>
  );
}

export default PanelDisplay;
