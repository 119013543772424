import { Route, Routes } from "react-router-dom";
import PanelRender from "./views/panelRender";
import PanelDisplay from "./views/panelDisplay";
import Home from "./views/home";

export const pathAlias = {
 "/": "início",
 "/estatistico": "estatistico",
 "/geoespacial": "geoespacial",
 "/all": "all",
 "/:type/display/:panel": "painel"
}

export function RoutesProvider() {
 return (
     <Routes>
     <Route path="/" element={<Home />} />
     <Route path="/estatistico" element={<PanelDisplay type="estatistico" />} />
     <Route path="/geoespacial" element={<PanelDisplay type="geoespacial" />} />
     <Route path="/all" element={<PanelDisplay  type="all" />} />
     <Route path="/:type/display/:panel" element={<PanelRender />} />
   </Routes>
 )
}
