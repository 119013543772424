import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PanelNav from "../../components/panelNav";
import PanelMapper from "../../utils/pannelMapper";
import "./index.css";

function PanelRender() {
  const [reRender, setReRender] = useState(0);
  const [url, setUrl] = useState();

  const mapper = useMemo(() => new PanelMapper(), []);
  const { type, panel } = useParams();

  useEffect(() => {
    setReRender(Date.now());
    setUrl(mapper.getUrl(panel, type));
  }, [panel, mapper, type]);

  return (
    <>
      <iframe
        title="Painel temático"
        key={reRender}
        src={url}
        id="panel-render-frame"
      />
      <PanelNav type={type} />
    </>
  );
}

export default PanelRender;
