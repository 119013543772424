import "./index.css";
import Home from "../../assets/imgs/home-icon.png";
import Layer from "../../assets/imgs/layer-icon.png";
import Chart from "../../assets/imgs/chart-icon.png";
import { Link } from "react-router-dom";

function PanelNav({ type }) {
  return (
    <nav className="panel-display">
      <Link to="/all" className={type === "all" ? "active" : ""}>
        <img src={Home} alt="" />
        <span>Início</span>
      </Link>
      <Link
        to="/estatistico"
        className={type === "estatistico" ? "active" : ""}
      >
        <img src={Chart} alt="" />
        <span>Estatístico</span>
      </Link>
      <Link
        to="/geoespacial"
        className={type === "geoespacial" ? "active" : ""}
      >
        <img src={Layer} alt="" />
        <span>Geoespacial</span>
      </Link>
    </nav>
  );
}

export default PanelNav;
