import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PanelMapper from "../../utils/pannelMapper";
import Logo from "../../assets/imgs/logo_branco.png";
import Header from "../../assets/imgs/header_bg.png";
import Estatistico from "../../assets/imgs/estatistico.png";
import Geoespacial from "../../assets/imgs/geoespacial.png";
import "./index.css";
import useOutsideAlerter from "../../hooks/outsideAlerter";

function PanelListing({ type }) {
  const [query, setQuery] = useState("");
  const [color, setColor] = useState("#0f7546");
  const [placeholder, setPlaceholder] = useState("Busque um tema ou dados");
  const [panelModal, setPanelModal] = useState();
  const [panels, setPanels] = useState([]);

  const mapper = useMemo(() => new PanelMapper(), []);
  const modalRef = useRef();
  const navigate = useNavigate();
  useOutsideAlerter(modalRef, setPanelModal);

  const handleListingClick = (panel) => {
    if (type === "all" && panel.geoespacial && panel.estatistico) {
      return setPanelModal(panel);
    }
    if (type === "all" && panel.geoespacial) {
      return navigate(`/geoespacial/display/${panel.title}`);
    }
    if (type === "all" && panel.estatistico) {
      return navigate(`/estatistico/display/${panel.title}`);
    }

    navigate(`/${type}/display/${panel.title}`);
  };

  useEffect(() => {
    setPanels(mapper.list(query, type));
    switch (type) {
      case "estatistico":
        setPlaceholder("Busque painéis estatístico");
        setColor("#408198");
        break;
      case "geoespacial":
        setPlaceholder("Busque painéis geoespaciais");
        setColor("#bb9946");
        break;
      default:
        setPlaceholder("Busque um tema ou dados");
        setColor("#0f7546");
        break;
    }
  }, [type, query, mapper]);

  return (
    <>
      <div className={`panel-listing-modal ${!!panelModal ? "shown" : ""}`}>
        <div ref={modalRef}>
          <button className="close-btn" onClick={() => setPanelModal()}>
            <span>✕</span>
          </button>
          <p>Como você gostaria de visualizar os dados do tema selecionado?</p>
          <Link to={`/estatistico/display/${panelModal?.title}`}>
            <img src={Estatistico} alt="" />
            <div>Estatístico</div>
          </Link>
          <p className="center">
            <small>Gráficos e dados totalizados</small>
          </p>
          <Link to={`/geoespacial/display/${panelModal?.title}`}>
            <img src={Geoespacial} alt="" />
            <div>Geoespacial</div>
          </Link>
          <p className="center">
            <small>Mapas e camadas geoespaciais</small>
          </p>
        </div>
      </div>
      <header
        className="panel-listing-header"
        style={{ backgroundColor: color }}
      >
        <img src={Header} alt="" className="bg" />
        <img src={Logo} alt="" className="logo" />
        <input
          onChange={(ev) => setQuery(ev.currentTarget.value)}
          value={query}
          placeholder={placeholder}
        />
      </header>
      <article className="panel-listing-container">
        {panels.map((panel) => (
          <button
            onClick={() => handleListingClick(panel)}
            key={panel.title}
            className="panel-listing"
            style={{ borderColor: color, backgroundColor: color }}
          >
            <div
              style={{
                backgroundImage: `linear-gradient(to right, ${color} 61%, transparent 90%)`,
              }}
            >
              {panel.title}
            </div>
            <img src={panel.icon} alt="" />
          </button>
        ))}
      </article>
    </>
  );
}

export default PanelListing;
