import React from "react";
import "./App.css";
import ReactGA from "react-ga4";
import { WatchRoutesProvider } from "./watchRoutesProvider";
import { RoutesProvider } from "./routes";

if (window.location.hostname === 'observatorio.agropecuaria.inmet.gov.br') {
 ReactGA.initialize('G-JJ5PZSMZW0');
}

function App() {
 return (
   <>
     <RoutesProvider />
     <WatchRoutesProvider />
   </>
 )
}

export default App;
