import "./index.css";
import Logo from "../../assets/imgs/logo_branco.png";
import Chart from "../../assets/imgs/chart-icon.png";
import Layer from "../../assets/imgs/layer-icon.png";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  const [scrollStep, setScrollStep] = useState(0);

  const navigate = useNavigate();
  const scrollRef = useRef();

  useEffect(() => {
    if (localStorage.getItem("hideHome") === "true") {
      navigate("/all");
    }

    function scrollHandler(e) {
      var atSnappingPoint = e.target.scrollLeft % e.target.offsetWidth === 0;
      var timeOut = atSnappingPoint ? 0 : 150;

      clearTimeout(e.target.scrollTimeout);

      e.target.scrollTimeout = setTimeout(function () {
        setScrollStep(e.target.scrollLeft / e.target.offsetWidth);
      }, timeOut);
    }

    document
      .querySelector(".panel-container")
      .addEventListener("scroll", scrollHandler);

    return () => {
      const el = document.querySelector(".panel-container");
      el?.removeEventListener("scroll", scrollHandler);
    };
  }, [navigate]);

  useEffect(() => {
    if (!scrollRef) return;
    const el = scrollRef.current;
    const step = el.scrollLeft / el.offsetWidth;
    if (step === scrollStep) return;
    const scroll = el.offsetWidth * scrollStep;
    el.scrollTo({
      left: scroll,
      behavior: "smooth",
    });
  }, [scrollStep]);

  return (
    <main id="home-container">
      <div className="panel-container" ref={scrollRef}>
        <section className="home-section">
          <p>Bem vindo ao</p>
          <img src={Logo} alt="" />
          <p className="center">
            Dados da agropecuária na <strong>palma da mão</strong>
          </p>
        </section>
        <section className="home-section">
          <p className="center">
            Acesse os dados da agropecuária brasileira em tempo real
          </p>
          <Link className="platform-button" to="/estatistico">
            <div>
              <img src={Chart} alt="Plataforma estatística" />
            </div>
            Plataforma Estatística
          </Link>
          <Link className="platform-button" to="/geoespacial">
            <div>
              <img src={Layer} alt="Plataforma geoespacial" />
            </div>
            Plataforma Geoespacial
          </Link>
        </section>
      </div>
      <div className="scroll-step-container">
        <div
          onClick={() => setScrollStep(0)}
          className={`scroll-step ${scrollStep === 0 ? "active" : ""}`}
        />
        <div
          onClick={() => setScrollStep(1)}
          className={`scroll-step ${scrollStep === 1 ? "active" : ""}`}
        />
      </div>
    </main>
  );
}

export default Home;
