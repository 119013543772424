import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from  'react-ga4'
import { pathAlias } from "./routes";

export function WatchRoutesProvider({ children }) {
 const location = useLocation();

 useEffect(() => {
   let title = pathAlias[location.pathname];
   if (title === undefined) {
     title = location.pathname
     if (location?.pathname?.includes('display')) {
       title = `display ${location?.pathname?.split('/')?.pop()}`
     }
   }
   ReactGA.send({ hitType: "pageview", page: location.pathname, title });
 }, [location]);

 return children;
}
